import React from "react";

import "./style.css";

function Footer() {
  return (
    <footer className="section-5 center" id="section-5">
      <div className="social-media">
        <a
          target="_blank"
          href="https://github.com/akpg01"
          className="social-media-link"
          rel="noreferrer"
        >
          <i className="fab fa-github-square"></i>
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/graysea2"
          className="social-media-link"
          rel="noreferrer"
        >
          <i className="fab fa-facebook-square"></i>
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/in/g-akpan"
          className="social-media-link"
          rel="noreferrer"
        >
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
      <p className="copyright">
        &copy; {new Date().getFullYear()}. All Rights Reserved.
      </p>
    </footer>
  );
}

export default Footer;
