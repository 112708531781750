import React from "react";
import "./style.css";

function About() {
  return (
    <section className="section-2" id="about">
      <div className="section-heading section-2-heading">This is Me</div>
      <div className="services">
        <div className="service">
          <i className="fas fa-users"></i>
          <h2 className="service-heading">User Experience</h2>
          <div className="service-description">
            I have over 20 years of work experience in the banking and finance
            industries combined. I interact with internal and external clients
            on a daily basis. This allows me to collect and pass along their
            feedback through the proper channels. The feedback from customers
            has positively influenced my design process; such that, the focus of
            every project I create is driven by the need to not only make an
            application aesthetically pleasing, functional and responsive but
            also intuitive for the user.
          </div>
        </div>
        <div className="service">
          <i className="fas fa-book-reader"></i>
          <h2 className="service-heading">Willingness to Learn</h2>
          <div className="service-description">
            There is no question that technology is always changing, so I
            understand the importance of keeping up to date. I have a Bachelor
            degree in Information Systems and a Master degree in Computer
            Science. In order to stay current, I participate in Massive Online
            Courses (MOOCs) that allow me to not only gain knowledge but also
            solidify existing skill sets. The most fun and important part of
            learning is applying my skills to projects of various sizes. I enjoy
            the process involved in developing a concept into a user centered
            product.
          </div>
        </div>
        <div className="service">
          <i className="fas fa-cogs"></i>
          <h2 className="service-heading">High-Quality Applications</h2>
          <div className="service-description">
            My goal with each project I undertake is to deliver a high quality
            application that encompasses the requirements provided by the
            stakeholders. Meeting these requirements may entail researching the
            market segment and the web design that appeals to that market. It
            also requires researching technologies that can be used in the
            implementation process.
          </div>
        </div>
      </div>
      <div className="section-2-details">
        <div className="section-2-container">
          <div className="column-1">
            <div className="why-hire">
              <h2 className="column-1-heading">Why should you hire me?</h2>
              <p className="column-1-description">
                I am dependable, pay attention to details and strive to deliver
                the best quality projects in a timely manner. I also have
                diverse work experiences and education that allows me to
                approach a project from different angles. My goal is to produce
                an application that is not only aesthetically appealing but
                intuitive to the user. So, if you would like to build something
                together or have additional questions, please send me an{" "}
                <a href="#contact">email</a>.
              </p>
            </div>
            <div className="credentials">
              <h2 className="column-1-heading">Education</h2>
              <ul>
                <li>
                  <span>Master of Science, Computer Science</span>, University
                  of Utah, School of Computing, USA
                </li>
                <li>
                  <span>Bachelor of Science, Information Systems</span>,
                  University of Utah, David Eccles School of Business, USA
                </li>
                <li>
                  <span>Associate of Arts, Business</span>, Salt Lake Community
                  College, School of Business, USA
                </li>
              </ul>
            </div>
          </div>
          <div className="progress-bars-wrapper">
            <div className="progress-bar">
              <p className="progress-text">
                Java <span>85</span>%
              </p>
              <div className="progress-percentage"></div>
            </div>
            <div className="progress-bar">
              <p className="progress-text">
                React JS <span>85</span>%
              </p>
              <div className="progress-percentage"></div>
            </div>
            <div className="progress-bar">
              <p className="progress-text">
                JavaScript <span>90</span>%
              </p>
              <div className="progress-percentage"></div>
            </div>
             <div className="progress-bar">
              <p className="progress-text">
                TypeScript <span>75</span>%
              </p>
              <div className="progress-percentage"></div>
            </div>
            <div className="progress-bar">
              <p className="progress-text">
                CSS <span>90</span>%
              </p>
              <div className="progress-percentage"></div>
            </div>
            <div className="progress-bar">
              <p className="progress-text">
                HTML <span>90</span>%
              </p>
              <div className="progress-percentage"></div>
            </div>
             <div className="progress-bar">
              <p className="progress-text">
                Angular <span>70</span>%
              </p>
              <div className="progress-percentage"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
