import React, { useState, useEffect, createRef } from "react";
import "./style.css";

function Dropdown({ title, list, resetThenSet, searchable }) {
  const [keyword, setKeyword] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState(title);

  const searchField = createRef();

  const toggleList = () => {
    setIsListOpen(!isListOpen);
    setKeyword("");
    if (isListOpen && searchField.current) {
      searchField.current.focus();
      setKeyword("");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (isListOpen) {
        window.addEventListener("click", close);
      } else {
        window.removeEventListener("click", close);
      }
    }, 0);
    return () => {
      window.removeEventListener("click", close);
    };
  });

  const filterList = (e) => {
    console.log("yo, i got to the filterList.....");
    setKeyword(e.target.value.toLowerCase());
  };

  const close = () => {
    setIsListOpen(false);
  };

  const selectItem = (item) => {
    const { title, id } = item;
    setHeaderTitle(title);
    setIsListOpen(false);
    resetThenSet(id);
  };

  const listItems = () => {
    let tempList = [...list];

    if (keyword.length) {
      tempList = list
        .filter((item) =>
          item.title.toLowerCase().slice(0, keyword.length).includes(keyword)
        )
        .sort((a, b) => {
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        });
    }

    if (tempList.length) {
      return tempList.map((item) => (
        <button
          type="button"
          className="dd-list-item"
          key={item.id}
          onClick={() => selectItem(item)}
        >
          {item.title} {item.selected && <i className="fas fa-check"></i>}
        </button>
      ));
    }
    return <div className="dd-list-item no-result">{searchable[1]}</div>;
  };

  return (
    <>
      <div className="dd-wrapper">
        <button className="dd-header" type="button" onClick={toggleList}>
          <div className="dd-header-title">{headerTitle}</div>
          {isListOpen ? (
            <i className="fas fa-angle-up fa-lg"></i>
          ) : (
            <i className="fas fa-angle-down fa-lg"></i>
          )}
        </button>
        {isListOpen && (
          <div className={`dd-list ${searchable ? "searchable" : ""}`}>
            {searchable && (
              <input
                ref={searchField}
                className="dd-list-search-bar"
                placeholder={searchable[0]}
                onChange={(e) => filterList(e)}
              />
            )}
            <div className="dd-scroll-list" role="list">
              {listItems()}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Dropdown;
