import React from "react";
import Spinner from "../spinner";
import "./style.css";

function Card({ title, technologies, image, alt, url }) {
  return (
    <>
      {image ? (
        <div className="project">
          <div className="project-text">
            <h2 className="project-name">{title}</h2>
            <h4 className="project-technologies">
              {technologies.map((el, j) =>
                j + 1 < technologies.length ? el + "/" : el + ""
              )}
            </h4>
          </div>
          <img src={image} alt={alt} className="project-img" />
          <a
            target="_blank"
            href={url || "#none"}
            className="project-link"
            rel="noopener noreferrer"
          >
            Go to Project
          </a>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default Card;
