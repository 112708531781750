import React, { useState } from "react";
import Recaptcha from "react-google-recaptcha";
import axios from "axios";
import "./style.css";

import { showErrorMessage, showSuccessMessage } from "../helpers/alerts";

function Contact() {
  const [verified, setVerified] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
    success: "",
    error: "",
  });

  const { name, email, message, success, error } = values;

  const verifyCallback = (response) => {
    if (response) {
      setVerified(true);
    }
  };

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      success: "",
      error: "",
      [name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      setValues({ ...values, error: "Please complete all fields" });
    } else {
      if (verified) {
        setValues({
          ...values,
          error: "",
        });

        // send data to backend for email
        axios({
          method: "POST",
          url: `/contact`,
          data: { name, email, message },
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        })
          .then((response) => {
            console.log("Email sent successfully", response);
            setValues({
              ...values,
              name: "",
              message: "",
              email: "",
              success: "Email successfully sent",
              error: "",
            });
            window.grecaptcha.reset();
          })
          .catch((error) => {
            console.log("Error sending contact form", error);
            setValues({
              ...values,
              error: "Email submission failed. Please try again later.",
            });
          });
      } else {
        setValues({
          ...values,
          error: "Please confirm that you are a human.",
        });
      }
    }
  };

  return (
    <section className="section-4" id="contact">
      <h1 className="section-heading section-4-heading">Contact Me</h1>
      <p className="invite">Have questions or want to work together?</p>
      <form className="contact-form center" onSubmit={handleSubmit}>
        {success && showSuccessMessage(success)}
        {error && showErrorMessage(error)}

        <input
          type="text"
          placeholder="Name"
          onChange={handleChange("name")}
          value={name}
          required
        />
        <input
          type="email"
          placeholder="Email address"
          value={email}
          onChange={handleChange("email")}
          required
        />
        <textarea
          placeholder="Message"
          onChange={handleChange("message")}
          value={message}
          required
        ></textarea>
        <Recaptcha
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={verifyCallback}
        />
        <input type="submit" value="Submit" className="contact-form-btn" />
      </form>
    </section>
  );
}

export default Contact;
