import React from "react";
import "./style.css";

export const ChevronLeftLarge = ({ disable }) => (
  <div id="chevron-left" className={disable}></div>
);

export const ChevronRightLarge = ({ disable }) => (
  <div id="chevron-right" className={disable}></div>
);
