import React from "react";
import "./style.css";
import Navbar from "../components/navbar";
import About from "../components/about";
import Portfolio from "../components/portfolio";
import Contact from "../components/contact";
import Footer from "../components/footer";
import { useScrollPosition } from "../hooks/scrollPosition";

function Home() {
  useScrollPosition(() => {
    const navbar = document.querySelector(".navbar");
    const navbarOffsetTop = navbar.offsetTop;
    const sections = document.querySelectorAll("section");
    const navbarLinks = document.querySelectorAll(".navbar-link");
    const progress = document.querySelector(".progress-bars-wrapper");
    const progressBarPercents = [85, 85, 90, 75, 90, 90,70];

    if (window.pageYOffset > navbarOffsetTop) {
      navbar.classList.add("sticky");
    } else {
      navbar.classList.remove("sticky");
    }

    sections.forEach((section, i) => {
      if (window.pageYOffset >= section.offsetTop - 10) {
        navbarLinks.forEach((navbarLink) => {
          navbarLink.classList.remove("change");
        });
        navbarLinks[i].classList.add("change");
      }
    });

    if (window.pageYOffset + window.innerHeight >= progress.offsetTop) {
      document.querySelectorAll(".progress-percentage").forEach((el, i) => {
        el.style.width = `${progressBarPercents[i]}%`;
        el.previousElementSibling.firstElementChild.textContent =
          progressBarPercents[i];
      });
    }
  });

  return (
    <div className="container">
      <section className="section-1 center" id="home">
        <div className="section-1-heading">
          <p>
            Hi, I'm <span className="person-name">Grace Akpan</span>.
          </p>
          <p>I'm a Software Engineer.</p>
        </div>
        <a href="#portfolio" className="section-1-btn">
          View my work <i className="fa fa-arrow-right"></i>
        </a>
      </section>
      <Navbar />
      <About />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
