import React, { useEffect, useState } from "react";
import Pagination from "../Paginate";
import "./style.css";

const PaginationWrapper = ({ itemsPerPage = 1, items, page, buttons }) => {
  const [currentPage, setCurrentPage] = useState(page || 1);
  const [cardsPerPage] = useState(itemsPerPage || 10);

  useEffect(() => {
    setCurrentPage(page || 1);
  }, [page]);

  let numberOfItemsPerPage =
    items.length < itemsPerPage ? items.length : itemsPerPage;

  // if index of last card is negative, set to zero
  let indexOfLastCard = currentPage * numberOfItemsPerPage;
  indexOfLastCard = indexOfLastCard < 0 ? 0 : indexOfLastCard;

  // if index of first card is negative set to zero
  let indexOfFirstCard = indexOfLastCard - cardsPerPage;
  indexOfFirstCard = indexOfFirstCard < 0 ? 0 : indexOfFirstCard;

  let currentCards = items.slice(indexOfFirstCard, indexOfLastCard);

  const totalPages = Math.ceil(items.length / cardsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const next = () => {
    if (currentPage > 0 && currentPage + 1 <= totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previous = () => {
    if (currentPage - 1 > 0 && currentPage <= totalPages) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [currentCards]);

  return (
    <>
      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          paginate={paginate}
          currentPage={currentPage}
          next={next}
          previous={previous}
          buttons={buttons}
        />
      )}
    </>
  );
};

export default PaginationWrapper;
