import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Card from "./Card";
import "./style.css";
import Spinner from "../spinner";

function Portfolio() {
  const [projects, setProjects] = useState(null);
  useEffect(() => {
    axios
      .get("/projects")
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <section className="section-3" id="portfolio">
      <h1 className="section-heading section-3-heading">My Projects</h1>
      <div className="projects-wrapper center">
        {projects ? (
          projects.map((el, i) =>
            i < 9 ? (
              <Card
                key={el.title + i}
                title={el.title}
                technologies={el.technologies}
                image={el.image}
                alt={el.alt}
                url={el.url}
              />
            ) : (
              <Spinner />
            )
          )
        ) : (
          <Spinner />
        )}
      </div>
      <div className="all-projects">
        {projects && projects.length > 9 && (
          <Link target="_blank" to="/projects" className="all-projs">
            See All Projects
          </Link>
        )}
      </div>
    </section>
  );
}

export default Portfolio;
