import React from "react";
import { ChevronLeftLarge, ChevronRightLarge } from "../chevron";
import "./style.css";

function Pagination({
  totalPages,
  paginate,
  currentPage,
  next,
  previous,
  buttons,
}) {
  let maxLeft = currentPage - Math.floor(buttons / 2);
  let maxRight = currentPage + Math.floor(buttons / 2);

  if (maxLeft < 1) {
    maxLeft = 1;
    maxRight = buttons;
  }

  if (maxRight > totalPages) {
    maxLeft = totalPages - (buttons - 1);
    if (maxLeft < 1) {
      maxLeft = 1;
    }
    maxRight = totalPages;
  }

  const pageNumbers = [];
  for (let i = maxLeft; i <= maxRight; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="pagination">
      <li
        className={currentPage === 1 ? "disable" : ""}
        onClick={() => previous()}
      >
        <a href="#/" className="page-link pagination-arrows">
          <ChevronLeftLarge disable={currentPage === 1 ? "disable" : ""} />
        </a>
      </li>
      <div className="pagination-numbers">
        {pageNumbers.map((num) => (
          <li
            key={num}
            className={currentPage === num ? "active disable" : ""}
            onClick={() => paginate(num)}
          >
            <a href="#/" className="page-link">
              {num}
            </a>
          </li>
        ))}
      </div>
      <li
        className={currentPage === totalPages ? "disable" : ""}
        onClick={() => next()}
      >
        <a href="#/" className="page-link pagination-arrows">
          <ChevronRightLarge
            disable={currentPage === totalPages ? "disable" : ""}
          />
        </a>
      </li>
    </ul>
  );
}

export default Pagination;
