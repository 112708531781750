import "./App.css";
import axios from "axios";
import { BrowserRouter as Router, Route } from "react-router-dom";

// Pages
import Home from "./pages";
import Contact from "./components/contact";
import About from "./components/about";
import Projects from "./pages/allProjects";

axios.defaults.baseURL =
  "https://us-central1-portfolio-site-eb2af.cloudfunctions.net/api";

function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/" component={Home} exact />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/projects" exact component={Projects} />
      </Router>
    </div>
  );
}

export default App;
