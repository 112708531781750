import React, { useEffect, useState } from "react";
import Spinner from "../../components/spinner";
import PaginationWrapper from "../../components/pagination/pagination-wrapper";
import Dropdown from "../../components/dropdown";

import axios from "axios";
import "../../components/portfolio/style.css";

function Projects() {
  const selectOptions = [
    { id: 0, title: "All Projects", selected: false },
    { id: 1, title: "JS", selected: false },
    { id: 2, title: "HTML", selected: false },
    { id: 3, title: "CSS", selected: false },
    { id: 4, title: "React JS", selected: false },
    { id: 5, title: "MongoDB", selected: false },
    { id: 6, title: "Node", selected: false },
    { id: 7, title: "Firebase", selected: false },
    { id: 8, title: "JQuery", selected: false },
    { id: 9, title: "TypeScript", selected: false },
    { id: 10, title: "Angular", selected: false },
    { id: 11, title: "Bootstrap", selected: false },
  ];

  const [projects, setProjects] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState(projects);
  const [option, setOption] = useState(null);
  const [updatedOptions, setUpdatedOptions] = useState(selectOptions);

  const sortOptions = updatedOptions.sort((a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
    return 0;
  });

  useEffect(() => {
    axios
      .get("/projects")
      .then((res) => {
        setProjects(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    setUpdatedOptions(sortOptions);
  }, [selectedProjects]);

  console.log("projects: ", projects);
  useEffect(() => {
    if (projects) {
      const listProj = projects.filter((el) => {
        if (option && option.title.toLowerCase() === "all projects") {
          return el;
        }
        const techsLowerCase = el.technologies.map((tech) =>
          tech.toLowerCase()
        );
        return option && techsLowerCase.includes(option.title.toLowerCase());
      });
      setSelectedProjects(listProj);
    }
  }, [option]);

  const resetThenSet = (id) => {
    selectOptions.forEach((item) => {
      item.selected = false;
      if (item.id === id) {
        item.selected = true;
        setOption(item);
      }
    });

    setUpdatedOptions(selectOptions);
  };

  return (
    <>
      <section className="all-projects-section" id="portfolio">
        <h1 className="section-heading section-3-heading all-projects-heading">
          My Projects
        </h1>
        <Dropdown
          title="Select technology"
          list={updatedOptions}
          resetThenSet={resetThenSet}
          searchable={["Search for technology"]}
        />
        {!projects ? (
          <Spinner />
        ) : (
          <PaginationWrapper
            items={!selectedProjects ? projects : selectedProjects}
            itemsPerPage={9}
            page={1}
            buttons={5}
          />
        )}
      </section>
    </>
  );
}

export default Projects;
