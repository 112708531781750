import React from "react";
import "./style.css";

function Navbar() {
  return (
    <nav className="navbar center">
      <a href="#home" className="navbar-link">
        Home
      </a>
      <a href="#about" className="navbar-link">
        About
      </a>
      <a href="#portfolio" className="navbar-link">
        Portfolio
      </a>
      <a href="#contact" className="navbar-link">
        Contact
      </a>
    </nav>
  );
}

export default Navbar;
